.profile-info-container {

  text-align: center;
  padding-top: 21px;

  .profile-rounded-image {
    border-radius: 50%;
    display: block;
    width: 150px;
    height: 150px;
    object-fit: cover;
    transition: all 0.2s linear;
    padding-top: 0;
    padding-bottom: 0;
    margin: auto;
  }

  .profile-name {
    color: #b1b1b1;
    font-size: 23px;
    line-height: 24px;
    padding: 21px;
  }

}