.job-basic-info-container {

  .job-basic-info-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 30px;

    .job-basic-info-company {
      text-align: center;
      max-width: 50%;

      .job-basic-info-company-img {
        margin: auto;
      }

      .job-basic-info-company-info {
        padding-top: 21px;
        padding-bottom: 21px;
        margin: auto;

        .job-basic-info-company-name {
          font-size: 35px;
          padding-bottom: 10px;
        }

        .job-basic-info-company-profession {
          font-size: 23px;
          color: #b1b1b1;
        }
      }
    }

    .job-basic-info-button {
      background-color: var(--primary);
      color: var(--button-background);
      height: 45px;
      width: 128px;
      border-radius: 40px;
    }

    .job-basic-info-box-info {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 82%;
      display: flex;
      align-items: center;

      .job-basic-info-box-info-rate-label {
        width: 50%;
        font-size: 17px;
      }

      .job-basic-info-box-info-rate {
        width: 50%;
        font-size: 31px;
        font-weight: bold;
        margin-right: 0;
        text-align: end;
      }

    }

  }

}