.productDatatable {
  min-height: 600px;
  padding: 20px;

  .productDatatableTitle{
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .productDatatableFilters {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 0;

    .link{
      text-decoration: none;
      color: #039be5;
      font-size: 19px;
      font-weight: 400;
      border: 1px solid #039be5;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
      height: 100%;
    }
  }

  .rounded-table-header {
    border-radius: 21px;
  }

  .rounded-table-body {
    border-radius: 21px;
  }

  .responsiveDatatable {
    min-width: 550px;
  }

  .productCellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }

  @media only screen and (max-width: 1300px) {
    .responsiveDatatable {
      min-width: 450px;
    }
  }

  @media only screen and (max-width: 900px) {
    .responsiveDatatable {
      min-width: 380px;
    }
  }

  @media only screen and (max-width: 600px) {
    .responsiveDatatable {
      min-width: 300px;
    }
  }
}