.channels-options-tag-container {
  display: flex;
  align-items: center;
  flex-wrap:wrap;

  .channels-options-tag {
    color: var(--primary);
    border-radius: 21px;
    padding: 5px 8px;
    margin: 5px;
  }

  .channels-options-tag-unselected {
    background: rgba(9,9,121, 0.08);
  }

  .channels-options-tag-selected {
    background: rgba(9,9,121, 0.25);
  }

  .channels-options-tag-unselected:hover {
    background: rgba(9,9,121, 0.17);
  }

}
