.image-slide-card {
  width: 221px;
  display: inline-block;
  margin: auto;
  vertical-align: middle;

  .image-slide-card-image {
    object-fit: cover;
  }

}
