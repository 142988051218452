.hiring-processes-body-page {
  background: var(--body-background);

  .hiring-processes-container {

    align-items: flex-start;
    display: flex;
    margin: 0 auto;

    .hiring-processes-detailed-info-content {
      padding: 21px;
      width: 100%;
      flex: 6;

      .hiring-process-detailed-info-container {
        padding-top: 11px;
        padding-bottom: 11px;
      }

    }

    .job-offer-body-content {

    }
  }

}
