.product {

  display: flex;
  width: 100%;
  .productContainer {
    flex: 6;

    .top {
      padding: 20px;
      display: flex;
      gap: 20px;

      .left {
        flex: 1;
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        position: relative;

        .item {
          display: flex;
          gap: 20px;

          .itemImg {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }
        }
      }

      .right {
        flex: 2;
      }
    }

    .bottom {
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      padding: 20px;
      margin: 10px 20px;
    }
  }

  .productTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .productItem {
    width: 43%;
  }

  @media only screen and (max-width: 800px) {
    .productItem {
      margin: unset !important;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

}