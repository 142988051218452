.list {

  .list-container {
    display: flex;
    background: #F8F8F8;

    .list-body {
      flex: 6;
    }
  }

}