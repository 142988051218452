.contributor-list {

  .contributor-list-container {
    display: flex;
    background: #F8F8F8;

    .contributor-list-body {
      flex: 6;
      display: flex;
      padding-top: 21px;
    }
  }

}