.card-collection-table {

  .card-collection-table-row {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .collection-row-width-33 {
    width: 33%;
  }

  .collection-row-width-66 {
    width: 67%;
  }

}