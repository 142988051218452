.card-slider-container {
  margin: auto;
  padding: 20px;

  .sliderTitle{
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    align-items: center;
    justify-content: space-between;
  }

  .card-slider-wrapper {
    width: 100%;
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;

    .slider {
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow-x: scroll;
      scroll-behavior: smooth;
    }

    .slider::-webkit-scrollbar {
      display: none;
    }

    .slider-icon {
      color: var(--primary);
      width: 40px;
      height: 40px;
      background: white;
      border-radius: 100%;
      position: absolute;
      opacity: 0.4;
      box-shadow: 0 6px 20px rgba(55, 55, 255, 0.17);
      cursor: pointer;
    }

    .slider-icon:hover {
      opacity: 1;
    }

    .left-arrow-icon {
      left: 8px;
    }

    .right-arrow-icon {
      right: 8px;
    }

    .slider-card {
      width: 165px;
      height: 250px;
      background: white;
      border-radius: 21px;
      display: inline-block;
      margin-left: 5px;
      margin-right: 5px;
      //box-shadow: 2px 2px 2px 2px rgb(255 55 95 / 12%);
      cursor: pointer;
    }

    .slider-card-image {
      width: 100%;
      height: 182px;
      background: gray;
      border-top-left-radius: 21px;
      border-top-right-radius: 21px;
      object-fit: scale-down;
      background: white;
    }

    .slider-card-title, .slider-card-description {
      padding-top: 10px;
      text-align: center;
    }

    .slider-card-title {
      font-weight: 500;
    }

    .slider-card-description {
      opacity: 0.6;
      font-size: 14px;
    }
  }

}

@media only screen and (max-width: 2000px) {
  .card-slider-container {
    max-width: 1621px;
  }
}

@media only screen and (max-width: 1920px) {
  .card-slider-container {
    max-width: 1505px;
  }
}

@media only screen and (max-width: 1800px) {
  .card-slider-container {
    max-width: 1388px;
  }
}

@media only screen and (max-width: 1650px) {
  .card-slider-container {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 1400px) {
  .card-slider-container {
    max-width: 1182px;
  }
}

@media only screen and (max-width: 1200px) {
  .card-slider-container {
    max-width: 1050px;
  }
}

@media only screen and (max-width: 1000px) {
  .card-slider-container {
    max-width: 821px;
  }
}

@media only screen and (max-width: 800px) {
  .card-slider-container {
    max-width: 608px;
  }
}

@media only screen and (max-width: 600px) {
  .card-slider-container {
    max-width: 475px;
  }
}

@media only screen and (max-width: 400px) {
  .card-slider-container {
    max-width: 308px;
  }
}
