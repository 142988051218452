.job-offer-body-page {
  background: var(--body-background);

  .job-offer-container {

    align-items: flex-start;
    display: flex;
    margin: 0 auto;

    .job-offer-detailed-info-content {
      padding-top: 21px;
      padding-left: 21px;
      padding-right: 21px;
      width: 100%;
      flex: 6;
    }

    .job-offer-body-content {

    }
  }

}
