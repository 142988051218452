.login-register-company-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
}

.login-register-logo-icon {
    background-image: url("../../public/images/veggihappy-kareerr_logo_black.png");
    height: 45px;
    width: 55px;
}

.login-register-company-name {
    color: #232323;
    padding-left: 11px;
}