.sidebar {
    flex: 1;
    border-right: 0.5px solid rgb(230, 227, 227);
    min-height: 100vh;

    .center {
        padding-left: 10px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            .title {
                font-size: 17px;
                font-weight: bold;
                color: #999;
                margin-top: 15px;
                margin-bottom: 5px;
            }

            li {
                display: flex;
                align-items: center;
                padding: 5px;
                cursor: pointer;

                &:hover {
                    background-color: #ece8ff;
                }

                .icon {
                    font-size: 30px;
                    color: var(--primary);
                }

                span {
                    font-size: 15px;
                    font-weight: 600;
                    color: #888;
                    margin-left: 10px;
                }
            }
        }
    }

    .bottom{
        display: flex;
        align-items: center;
        margin: 10px;

        .colorOption{
            width: 20px;
            height: 20px;
            border-radius: 5px;
            border: 1px solid #222;
            cursor: pointer;
            margin: 5px;

            &:nth-child(1){
                background-color: whitesmoke;
            }
            &:nth-child(2){
                background-color: #333;
            }
            &:nth-child(3){
                background-color: darkblue;
            }
        }
    }

    .sidebar-company-logo {
        color: #fff;
        justify-self: start;
        margin-left: 20px;
        cursor: pointer;
        text-decoration: none;
        font-size: 1.5rem;
        display: flex;
        align-items: center;
    }

    .sidebar-logo-icon {
        background-image: url("../../../public/images/veggihappy-kareerr_logo_black.png");
        height: 45px;
        width: 55px;
    }

    .sidebar-company-name {
        color: #232323;
        padding-left: 11px;
    }
}
