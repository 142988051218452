.titled-button-widget-container {
  padding: 30px 0;
  margin: auto;
  width: 69%;
  text-align: center;

  .titled-button-widget-text-container {
    padding: 30px 0;

    .titled-button-widget-title {
      font-size: 60px;
      line-height: 1.1;
      font-weight: 700;
      color: var(--primary);
    }

    .titled-button-widget-subtitle {
      line-height: 1.7;
      font-size: 25px;
      padding: 30px 0;
    }

  }

  .titled-button-widget-cta-button {
    background: black;
    color: white;
    width: 291px;
    min-height: 55px;
    display: inline-block;
    border-radius: 30px;
    text-align: center;
    text-decoration: none;
    font-size: 1.5rem;
    border: 2px solid var(--primary);
    padding: 8px 10px;
  }

}
