.enterprise-contributor-card-container {

  padding-bottom: 25px;
  padding-right: 25px;
  padding-left: 25px;

  .enterprise-contributor-card-box {
    display: flex;
    flex-flow: column;
    width: 291px;
    height: 321px;
    box-shadow: 0 6px 20px rgba(55, 55, 255, 0.17);
    border-radius: 50px;
    background: white;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 20px;

    .enterprise-contributor-card-profile {

      .enterprise-contributor-card-profile-img {
        margin: auto;
        width: 8rem;
        height: 8rem;
        border-radius: 2rem;
        max-width: 100%;
      }

      .enterprise-contributor-card-profile-info {
        padding-top: 15px;
        padding-bottom: 10px;
        margin: auto;

        .enterprise-contributor-card-profile-name {
          font-size: 21px;
          padding-bottom: 10px;
        }

        .enterprise-contributor-card-profile-profession {
          font-size: 15px;
          color: #b1b1b1;
          padding-bottom: 10px;
        }

        .enterprise-contributor-card-profile-channel {
          font-size: 19px;
          color: var(--primary);
        }
      }
    }

    .enterprise-contributor-card-button {
      background-color: var(--primary);
      color: var(--button-background);
      height: 45px;
      width: 128px;
      border-radius: 40px;
    }

    .enterprise-contributor-card-box-info {
      padding-top: 15px;
      padding-bottom: 15px;
      width: 82%;
      display: flex;
      align-items: center;

      .enterprise-contributor-card-box-info-rate-label {
        width: 50%;
        font-size: 17px;
      }

      .enterprise-contributor-card-box-info-rate {
        width: 50%;
        font-size: 31px;
        font-weight: bold;
        margin-right: 0;
        text-align: end;
      }

    }

  }

}