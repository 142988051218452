.rounded-image-card-item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  flex-flow: column;
  width: 100%;
  overflow: hidden;
  text-decoration: none;
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;

  .rounded-image-card-item-img {
    border-radius: 50%;
    display: block;
    width: 300px;
    height: 300px;
    object-fit: cover;
    transition: all 0.2s linear;
    padding-top: 0;
    padding-bottom: 0;
  }

  .rounded-card-item-info {
    padding: 15px 20px 15px;
  }

  .rounded-card-item-title {
    color: var(--primary);
    font-size: 25px;
    line-height: 24px;
    padding: 10px;
  }

  .rounded-card-item-subtitle {
    color: #b1b1b1;
    font-size: 19px;
    line-height: 24px;
    padding: 10px;
  }

  .rounded-card-item-text {
    text-align: justify;
  }
}
