.file-item {
  list-style: none;
  margin: 1.2em 0;
  background-color: rgba(0,191,255,0.2);
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 1.2em 1.5em;

  svg {
    color: var(--primary);
    &:first-child {
      font-size: 1.2em;
      margin-right: 0.8em;
    }
  }

  p {
    flex: 1;
    font-size: 0.9rem;
  }

  .actions {
    justify-self: flex-end;
    cursor: pointer;

    .fa-spinner {
      font-size: 1.2em;
    }
  }
}