.offer-body-page {
  background: var(--body-background);

  .offer-container {

    align-items: flex-start;
    display: flex;
    max-width: 70%;
    margin: 0 auto;
    padding-top: 21px;

    .offer-sticky-card-content {
      position: sticky;
      top: 100px;
    }

    .offer-detailed-info-content {
      padding-left: 21px;
      width: 100%;
    }

    .offer-body-content {

    }
  }

}
