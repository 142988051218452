.logo-titled-form-container {

  background: var(--body-background);

  .logo-titled-form-our-company-logo {

    color: #fff;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    padding: 21px 0;

    .logo-titled-form-logo-icon {
      background-image: url("../../../../public/images/veggihappy-kareerr_logo_black.png");
      height: 45px;
      width: 55px;
    }

    .logo-titled-form-our-company-name {
      color: #232323;
      padding-left: 11px;
    }

  }

  .logo-titled-form-text-container {

    text-align: center;
    padding: 20px 0;

    .logo-titled-form-title {
      font-size: 23px;
      line-height: 1.1;
      font-weight: 600;
      color: var(--primary);
    }

    .logo-titled-form-subtitle {
      line-height: 1.7;
      font-size: 15px;
      padding-top: 15px;
    }
  }
}
