.titled-slide-card {
  width: 250px;
  height: 250px;
  background: white;
  border-radius: 21px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  //box-shadow: 2px 2px 2px 2px rgb(255 55 95 / 12%);
  cursor: pointer;
}

.titled-slide-card-image {
  height: 182px;
  background: gray;
  border-radius: 50%;
  object-fit: scale-down;
  background: white;
  margin: auto;
  padding-top: 10px;
}

.titled-slide-card-title, .titled-slide-card-description {
  padding-top: 10px;
  text-align: center;
}

.titled-slide-card-title {
  font-weight: 500;
}

.titled-slide-card-description {
  opacity: 0.6;
  font-size: 14px;
}