.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: var(--button-background);
    border-radius: 1px solid var(--primary);
}

.btn--outline {
    background-color: var(--primary);
    border-radius: 17px;
    color: white;
    padding: 8px 20px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn-outline-gradient {
    background: linear-gradient(90deg, rgba(5,4,69,1) 0%, rgba(9,9,121,1) 35%, rgba(0,191,221,1) 100%);
    color: white;
    padding: 8px 20px;
    border: 2px solid black;
    transition: all 0.3s ease-out;
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn-rounded-medium {
    padding: 8px 20px;
    font-size: 20px;
    border-radius: 55px;
    width: 121px;
}

.btn--medium {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--large {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover {
    background: var(--button-background);
    color: var(--primary);
    transition: all 0.3s ease-out;
}