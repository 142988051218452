.account-options-header-container {

  text-align: center;
  padding: 20px 0;

  .account-options-header-title {
    font-size: 23px;
    line-height: 1.1;
    font-weight: 600;
    color: var(--primary);
  }

  .account-options-header-subtitle {
    line-height: 1.7;
    font-size: 15px;
    padding-top: 15px;
  }

}

.account-options-container {
  display: flex;
  align-items: center;
  flex-wrap:wrap;
  justify-content: center;

  .account-options {
    color: var(--primary);
    border-radius: 21px;
    padding: 21px;
    margin: 5px;
    width: 150px;
    text-align: center;
  }

  .account-options-image {
    margin: auto;
    padding-bottom: 11px;
  }

  .account-options-text {
    font-size: 15px;
  }

  .account-options-unselected {
    background: rgba(9,9,121, 0.08);
  }

  .account-options-selected {
    background: rgba(9,9,121, 0.25);
  }

  .account-options-unselected:hover {
    background: rgba(9,9,121, 0.17);
  }

}