.job-offers-list {

  .job-offers-list-container {
    display: flex;
    background: #F8F8F8;

    .job-offers-list-body {
      flex: 6;
    }
  }

}