.detailed-job-position-container {

  border-radius: 50px;
  box-shadow: 0 6px 20px rgba(55, 55, 255, 0.17);
  background: white;
  padding: 30px 40px;

  .detailed-job-position-meta-information {
      display: flex;
  }

}