.posted-job-body-page {
  background: var(--body-background);

  .posted-job-container {

    align-items: flex-start;
    display: flex;
    max-width: 70%;
    margin: 0 auto;
    padding-top: 21px;

    .posted-job-sticky-card-content {
      position: sticky;
      top: 100px;
    }

    .posted-job-detailed-info-content {
      padding-left: 21px;
      width: 100%;
    }

    .posted-job-body-content {

    }
  }

}
