.job-position-skills-card-container {

  padding-bottom: 25px;
  width: 50%;

  .job-position-skills-card-box {
    display: flex;
    flex-flow: column;
    border-radius: 50px;
    padding-top: 30px;
    padding-bottom: 20px;

    .job-position-skills-title-container {
      width: 82%;
      padding-bottom: 10px;

      .job-position-skills-title {
        font-size: 21px;
      }
    }

    .job-position-skills-tag-container {
      display: flex;
      align-items: center;
      flex-wrap:wrap;
      width: 85%;

      .job-position-skills-tag {
        background: rgba(9,9,121, 0.17);
        color: var(--primary);
        border-radius: 21px;
        padding: 5px 8px;
        margin: 5px;
      }

    }

  }

}