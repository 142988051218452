.app.dark {
  background-color: #222;
  color: rgb(156, 156, 156);

  .chartGrid {
    stroke: rgba(228, 228, 228, 0.219);
  }

  .table {
    background-color: #222;

    .tableCell {
      color: gray;
    }
  }

  .dashboard-navbar {
    color: #999;
    border-color: #333;

    .search {
      border-color: gray;
    }
  }

  .sidebar {
    background-color: #222;
    border-color: #333;

    .top {
      .logo {
        color: #999;
      }
    }
    hr {
      border-color: #333;
    }

    ul {
      li {
        &:hover {
          background-color: #333;
        }

        .icon {
          color: #999;
        }
      }
    }

    .bottom {
      .colorOption {
        border: 1px solid #999;
      }
    }

    .sidebar-company-name{
      color: #999;
    }
  }

  .datatable {
    .dataGrid {
      color: gray;
      border: none;

      .viewButton,
      .deleteButton,
      .cellWithStatus {
        color: gray;
        border: none;
      }
    }
  }

  input{
    background-color: transparent;
  }
}