.hiring-process-detail-container {

  box-shadow: 0 6px 20px rgba(55, 55, 255, 0.17);
  border-radius: 50px;
  background: white;
  padding: 23px 50px;

  .hiring-process-detail-info-container {

    text-align: center;
    padding-top: 10px;
    padding-bottom: 21px;

    .candidate-job-text-container {
      padding-top: 8px;

      .hiring-process-candidate-name {
        color: #b1b1b1;
        font-size: 21px;
        line-height: 24px;
        padding: 10px;
      }

      .hiring-process-candidate-email {
        color: #b1b1b1;
        font-size: 17px;
        line-height: 24px;
        padding: 10px;
      }

      .candidate-job-text {
        font-size: 19px;
        line-height: 24px;
      }

    }

    .candidate-rounded-image {
      border-radius: 50%;
      display: block;
      width: 150px;
      height: 150px;
      object-fit: cover;
      transition: all 0.2s linear;
      padding-top: 0;
      padding-bottom: 0;
      margin: auto;
    }

  }

}
