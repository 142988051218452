.job-position-extra-info-card-container {

  padding-bottom: 25px;
  width: 50%;

  .job-position-extra-info-card-box {
    display: flex;
    flex-flow: column;
    border-radius: 50px;
    padding-top: 30px;
    padding-bottom: 20px;

    .job-position-extra-info-title-container {
      width: 82%;
      padding-bottom: 10px;

      .job-position-extra-info-title {
        font-size: 21px;
      }
    }
    .job-position-extra-info-card-box-info {
      padding-top: 5px;
      padding-bottom: 5px;
      width: 82%;
      display: flex;
      align-items: center;
      font-size: 17px;

      .job-position-extra-info-card-box-info-label {
        width: 50%;
        color: #b1b1b1;
      }

      .job-position-extra-info-card-box-info-value {
        width: 50%;
        margin-right: 0;
        color: gray;
      }

    }

  }

}