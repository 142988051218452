.new-job-offer {

  .new-job-offer-container {
    width: 100%;
    display: flex;

    .new-job-offer-body {
      flex: 6;

      .new-job-enterprise-logo {
        text-align: center;
        margin: auto;
        width: 30%;
      }

      .new-job-offer-title {
        width: 100%;
        font-size: 24px;
        color: gray;
        padding-left: 21px;
        padding-top: 15px;
      }

      .bottom {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 10px;
        margin: 20px;
        display: flex;

        h1 {
          color: lightgray;
          font-size: 20px;
        }

        .left {
          flex: 1;
          text-align: center;

          img {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }
        }

        .right {
          flex: 2;

          .new-job-offer-form {

            display: flex;
            flex-wrap: wrap;
            gap: 30px;
            justify-content: space-around;

            .formInput {
              width: 40%;

              label {
                display: flex;
                align-items: center;
                gap: 10px;

                .icon {
                  cursor: pointer;
                }
              }

              input {
                width: 100%;
                padding: 5px;
                border: none;
                border-bottom: 1px solid gray;
              }
            }

          }

          .new-job-offer-form-button-container {
            padding: 40px;
            margin: auto;
            text-align: center;

            .new-job-offer-form-button {
              width: 291px;
              height: 50px;
              border: none;
              background-color: var(--primary);
              color: white;
              font-weight: bold;
              cursor: pointer;
              border-radius: 91px;
            }
          }

        }
      }
    }

  }
}